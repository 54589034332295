// disabling for now since im still working on this.
/* eslint-disable */
import React, { useEffect } from 'react';
import { css } from '@emotion/core';
import scriptLoader from 'react-async-script-loader';
import GDPRJS from './assets/js/gdpr.js';
import './assets/css/gdpr.css';
import { graphql, useStaticQuery } from 'gatsby';
import CsodBlockContent from '../BlockContent/BlockContent';
import Link from '../../atoms/Link/Link';

const GDPR = ({ isScriptLoaded, isScriptLoadSucceed }) => {
  const queryResult = useStaticQuery(graphql`
    query {
      allSanitySiteSettings {
        nodes {
          _rawGdprBannerText(resolveReferences: { maxDepth: 10 })
          gdprAgreeButtonText
          gdprDisagreeButtonText
        }
      }
    }
  `);

  const gdprBannerData =
    queryResult &&
    queryResult.allSanitySiteSettings.nodes.length > 0 &&
    queryResult.allSanitySiteSettings.nodes[0]._rawGdprBannerText &&
    queryResult.allSanitySiteSettings.nodes[0].gdprAgreeButtonText &&
    queryResult.allSanitySiteSettings.nodes[0].gdprDisagreeButtonText
      ? queryResult.allSanitySiteSettings.nodes[0]
      : {
          _rawGdprBannerText: [
            {
              _key: '8254347e2984',
              _type: 'block',
              children: [
                {
                  _key: '8254347e29840',
                  _type: 'span',
                  marks: [],
                  text:
                    'By using our website, you are consenting to our use of ',
                },
                {
                  _key: '8254347e29841',
                  _type: 'span',
                  marks: ['74d44305d767'],
                  text: 'cookies',
                },
                {
                  _key: '8254347e29842',
                  _type: 'span',
                  marks: [],
                  text: ', as described in our ',
                },
                {
                  _key: '8254347e29843',
                  _type: 'span',
                  marks: ['cbf7b00aa904'],
                  text: 'privacy policy',
                },
                {
                  _key: '8254347e29844',
                  _type: 'span',
                  marks: [],
                  text: '. \nYou can change your ',
                },
                {
                  _key: '8254347e29845',
                  _type: 'span',
                  marks: ['39b1e45be4e5'],
                  text: 'cookie settings',
                },
                {
                  _key: '8254347e29846',
                  _type: 'span',
                  marks: [],
                  text: ' at any time.',
                },
              ],
              markDefs: [
                {
                  _key: '74d44305d767',
                  _type: 'link',
                  link: [
                    {
                      _id: '465e4762-dce8-4035-a2b8-32efaf7159ba',
                      _type: 'route',
                      _createdAt: '2020-04-01T05:57:50Z',
                      _rev: 'Jn6RtGi4TAcEovEOKU6uzG',
                      _updatedAt: '2020-04-16T21:12:00Z',
                      enabled: true,
                      routeName: 'Website Privacy Policy',
                      slug: {
                        _type: 'slug',
                        current: '/privacy-policy',
                      },
                      id: '3280c3e6-2f4e-5b40-a440-6dc96d13c5af',
                      parent: null,
                      children: [],
                      internal: {
                        type: 'SanityRoute',
                        contentDigest: 'ae33215a53f77e55e593b3371b0d459b',
                        owner: 'gatsby-source-sanity',
                      },
                    },
                  ],
                },
                {
                  _key: 'cbf7b00aa904',
                  _type: 'link',
                  link: [
                    {
                      _id: '465e4762-dce8-4035-a2b8-32efaf7159ba',
                      _type: 'route',
                      _createdAt: '2020-04-01T05:57:50Z',
                      _rev: 'Jn6RtGi4TAcEovEOKU6uzG',
                      _updatedAt: '2020-04-16T21:12:00Z',
                      enabled: true,
                      routeName: 'Website Privacy Policy',
                      slug: {
                        _type: 'slug',
                        current: '/privacy-policy',
                      },
                      id: '3280c3e6-2f4e-5b40-a440-6dc96d13c5af',
                      parent: null,
                      children: [],
                      internal: {
                        type: 'SanityRoute',
                        contentDigest: 'ae33215a53f77e55e593b3371b0d459b',
                        owner: 'gatsby-source-sanity',
                      },
                    },
                  ],
                },
                {
                  _key: '39b1e45be4e5',
                  _type: 'link',
                  link: [
                    {
                      _key: 'f981b11de94b',
                      _type: 'externalLink',
                      externalLink:
                        'http://www.allaboutcookies.org/manage-cookies/',
                    },
                  ],
                },
              ],
              style: 'normal',
            },
          ],
          gdprAgreeButtonText: 'Agree',
          gdprDisagreeButtonText: 'Disagree',
        };

  const gdprTextId = 'gdpr-text';

  useEffect(() => {
    if (isScriptLoaded) {
      if (isScriptLoadSucceed) {
        GDPRJS.init(gdprTextId, gdprBannerData);
      } else {
        console.log(
          'Some scripts could not be loaded. Please check your ad blocker and try again.'
        );
        try {
          GDPRJS.init(gdprTextId, gdprBannerData);
        } catch (err) {
          console.log('GDPRJS failed to launch :(', err);
        }
      }
    }
  }, []);

  const gdprTextCss = css`
    display: none;
    visibility: hidden;
  `;

  const serializers = {
    marks: {
      link: ({ _key, children, mark }) => {
        return (
          <span style={{ textDecoration: 'underline', }}>
            <Link key={_key} to={mark}>
              {children}
            </Link>
          </span>
        );
      },
    },
  };

  return (
    <div id={gdprTextId} css={gdprTextCss}>
      <CsodBlockContent
        blocks={gdprBannerData._rawGdprBannerText}
        serializers={serializers}
      />
    </div>
  );
};

export default scriptLoader(
  [
    '//code.jquery.com/jquery-3.3.1.min.js',
    '//cdn.jsdelivr.net/npm/js-cookie@2/src/js.cookie.min.js',
  ],
  ['//js.maxmind.com/js/apis/geoip2/v2.1/geoip2.js']
)(GDPR);
