/* eslint-disable */
const IN_EU = 'in-eu';
const NOT_IN_EU = 'not-in-eu';

/**
 * GDPR cookie name, value and expiration options.
 * Expire cookies in 390 days which is just under 13 months (395.4 days) to be compliant with GDPR
 */
const gdprConsentCookie = {
  name: 'gdpr-consent',
  values: { agree: 'agree', deleted: 'deleted', disagree: 'disagree' },
  expiration: 390
};

const gdprCommunicationsCookie = {
  name: 'gdpr-communications',
  values: { optIn: 'opt-in' },
  expiration: 390
};

const GDPRJS = {
  dataLayer: (typeof dataLayer === "undefined") ? [] : dataLayer,

  init:  function(gdprTextId, bannerData) {
    this.updateCookieExpiration();
    var self = this;

    //if the user isn't in the EU, trigger Google Tag Manager to load its GDPR sensitive scripts
    this.isUserInEuropeanUnion().then(function(userIsInEU){
      if(userIsInEU === IN_EU) {
        localStorage.setItem('gdpr-location', IN_EU);
        self.checkMarketoPrefCenterCookie().then(function(cookieInfo){
          if(cookieInfo === 'yes') {
            Cookies.remove(gdprConsentCookie.name, { path: '/'});
            Cookies.remove(gdprCommunicationsCookie.name, {path: '/'});
            self.addGDPRCookie(gdprConsentCookie.values.deleted);
          }
        }, function(error) {
          console.log(error);
        }).always(function() { self.executeGDPRCode(gdprTextId, bannerData); });
      } else {
        localStorage.setItem('gdpr-location', NOT_IN_EU);
        self.triggerGoogleTagManager();
      }
    });
  },

  /**
   * Update the expiration time of the gdpr-consent cookie if it exists and the
   * gdpr-consent-date-fix cookie is undefined.
   */
  updateCookieExpiration: function() {
    if(Cookies.get(gdprConsentCookie.name) &&
      Cookies.get('gdpr-consent-date-fix') === undefined) {
      this.addGDPRCookie(Cookies.get(gdprConsentCookie.name));
    }
  },

  /**
   * Add the GDPR cookie.
   * @param cookieValue.
   */
  addGDPRCookie: function(cookieValue) {
    Cookies.set(gdprConsentCookie.name, cookieValue, { expires: gdprConsentCookie.expiration, path: '/' });
    Cookies.set('gdpr-consent-date-fix', 'expiration-date-fixed', { expires: gdprConsentCookie.expiration, path: '/' });
  },

  /**
   * Use the Google Tag Manager API to fire a GTM trigger for GDPR
   */
  triggerGoogleTagManager: function() {
    this.dataLayer.push({event: 'gdpr-consent-granted'});
  },

  /**
   * Show the GDPR consent banner, update the GDPR cookie if the user agrees.
   * Also, update the GDPR cookie if the user agrees on a leadgen form page.
   */
  executeGDPRCode: function(gdprTextId, bannerData) {

    const $cookieAgreement = $('.cookie-agreement');
    const $cookieAgreementForMeasuring = $('.cookie-agreement-for-measuring');

    //if user has consented, then trigger Google Tag Manager
    if(Cookies.get(gdprConsentCookie.name) === gdprConsentCookie.values.agree) {
      GDPRJS.triggerGoogleTagManager();
    }

    //if the user hasn't consented and this is a page we want to show the banner on
    if(Cookies.get(gdprConsentCookie.name) !== gdprConsentCookie.values.agree && Cookies.get(gdprConsentCookie.name) !== gdprConsentCookie.values.disagree) {

      var gdprTextHtml = document.querySelector('#'+gdprTextId).innerHTML;

      var cookieBannerRawHtml = `
        <div class='cookie-agreement'>
          <div class='section'>
            <div class='cookie-agreement-text'>${gdprTextHtml}
            </div>
            <div class="cookie-agreement-buttons">
              <button class='cookie-disagreement__button'>${bannerData.gdprDisagreeButtonText}</button>
              <button class='cookie-agreement__button'>${bannerData.gdprAgreeButtonText}</button>
            </div>
          </div>
        </div>
      `;

      //append fake GDPR banner and display offscreen so we can calculate its height to send along with the event
      var $cookieBannerForMeasuring = $(cookieBannerRawHtml);
      $cookieBannerForMeasuring.addClass('cookie-agreement-for-measuring');
      $cookieBannerForMeasuring.css({
        display:"block",
        position: "absolute",
        zIndex: "-10000",
        visibility: "hidden"
      });

      if (!$cookieAgreementForMeasuring.length) {
        $('body').append($cookieBannerForMeasuring);
      }

      //add the real GDPR banner.
      var $bothGDPRBanners = $(cookieBannerRawHtml);
      if (!$cookieAgreement.is(':visible')) {
        $('body').prepend($bothGDPRBanners);
      }

      GDPRJS.displayGDPRBanner();

      //configure the button to close banner and set cookie
      $('.cookie-agreement__button').click(function () {
        GDPRJS.triggerGoogleTagManager();
        GDPRJS.addGDPRCookie(gdprConsentCookie.values.agree);
        const $clickedGDPRBanner = $(this).closest('.cookie-agreement');
        $clickedGDPRBanner.trigger('gdpr-banner-height-change-start', {state: "closed"});
        $clickedGDPRBanner.slideUp('default', function(){

          $clickedGDPRBanner.trigger('gdpr-banner-height-change-complete');
          $(window).off('resize', GDPRJS.displayGDPRBanner);
          //hide the other unclicked GDPR banner
          $cookieAgreement.hide();
        });
      });

      //configure the button to close banner and set a disagree cookie
      $('.cookie-disagreement__button').click(function () {
        GDPRJS.addGDPRCookie(gdprConsentCookie.values.disagree);
        const $clickedGDPRBanner = $(this).closest('.cookie-agreement');
        $clickedGDPRBanner.trigger('gdpr-banner-height-change-start', {state: "closed"});
        $clickedGDPRBanner.slideUp('default', function(){

          $clickedGDPRBanner.trigger('gdpr-banner-height-change-complete');
          $(window).off('resize', GDPRJS.displayGDPRBanner);
          //hide the other unclicked GDPR banner
          $cookieAgreement.hide();
        });
      });

      $(window).resize(GDPRJS.displayGDPRBanner);
    }
  },

  /**
   * Shows the GDPR banner and triggers the appropriate custom events so all.js can respond.
   */
  displayGDPRBanner: function() {
    var gdprBannerHeight = $('.cookie-agreement-for-measuring').outerHeight();
    var $activeGDPRBanner = $('.cookie-agreement');
    $activeGDPRBanner.trigger('gdpr-banner-height-change-start', {gdprBannerHeight: gdprBannerHeight, state: "open"});
    $activeGDPRBanner.slideDown('default', function(){
      $(this).trigger('gdpr-banner-height-change-complete');
    });
  },

  /**
   * Returns the correct header (moble or desktop) based on which one is visible.
   * @returns {*}
   */
  getActiveGDPRBanner: function() {
    var $headers = $('header, .mobile-header');
    var $activeHeader = $headers.filter('header').css('display') !== "none" ? $headers.filter('header') : $headers.filter('.mobile-header');
    return $activeHeader.find('.cookie-agreement');
  },

  /**
   * Check if a user is in the European Union or not using the Maxmind GeoIP2 API.
   * If API Fails to load, assume they are in EU.
   *
   * @return {*}
   */
  isUserInEuropeanUnion: function() {
    var deferred = $.Deferred();

    //allow us to trigger with URL param for testing
    if (window.location.href.match(/geo=not-in-eu/)) {
      deferred.resolve(NOT_IN_EU);
    } else if (window.location.href.match(/geo=in-eu/)) {
      deferred.resolve(IN_EU);
    }

    //if the api didn't load, show the cookie banner
    if (typeof geoip2 === "undefined") {
      deferred.resolve(IN_EU);
    } else {
      //call the Maxmind API
      geoip2.country(onSuccess, onError);
    }

    function onSuccess(data) {
      var userInEU = (data.country.is_in_european_union) ? IN_EU : NOT_IN_EU;
      deferred.resolve(userInEU);
    }

    function onError(error) {
      console.log(error);
      //default to true in case geoIP service fails
      deferred.resolve(IN_EU);
    }

    return deferred.promise();
  },

  /**
   * Loads an iframe with a blank Marketo page in it that has some JS to transfer cookies
   * between Marketo and our domains.
   *
   * @returns jQuery Promise that evaluates to 'yes' or '' or null
   */
  checkMarketoPrefCenterCookie: function() {
    var deferred = $.Deferred();

    window.addEventListener("message",
      function (e) {
        if (e.origin !== 'https://go.cornerstoneondemand.com') { return; }
        deferred.resolve(e.data);
      },false);


    //give the iframe 3 seconds to load
    setTimeout(function(){
      deferred.reject('iframe failed to load');
    },3000);


    GDPRJS.addMarketoIframe();

    return deferred.promise();
  },

  /**
   * Add the iframe of the Marketo page that sends its cookies to the "message" event
   * listener we've defined above.
   */
  addMarketoIframe: function() {
    const $curIframe = $('#marketo-pref-center');
    if (!$curIframe.length) {
      var iframeHtml = `<iframe id="marketo-pref-center" src="//go.cornerstoneondemand.com/Read-GDPR-Cookie.html"></iframe>`;
      $('body').append(iframeHtml);
      $('#marketo-pref-center').hide();
    }
  },

  /**
   * If the user decides to re-agree to GDPR, then we need to reset the cookie in the
   * Marketo Preference Center
   */
  resetMarketoPrefCenterCookie: function() {
    $('#marketo-pref-center')[0].contentWindow.postMessage('unset_gdpr_consent', 'http://go.cornerstoneondemand.com:80');
  },

  /**
   * Detect if a country is in the EU
   * @param countryName
   * @returns {boolean}
   */
  countryIsInEU: function(countryName) {
    const countriesInEU =
      [
        'Austria',
        'Belgium',
        'Bulgaria',
        'Croatia',
        'Cyprus',
        'Czech Republic',
        'Denmark',
        'Estonia',
        'Finland',
        'France',
        'Germany',
        'Greece',
        'Hungary',
        'Ireland',
        'Italy',
        'Latvia',
        'Lithuania',
        'Luxembourg',
        'Malta',
        'Netherlands',
        'Poland',
        'Portugal',
        'Romania',
        'Slovakia',
        'Slovenia',
        'Spain',
        'Sweden',
        'United Kingdom',
      ];

    return countriesInEU.indexOf(countryName) > -1;
  }

};

export default GDPRJS;
